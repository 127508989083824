/**
  * Overwrites to default bootstrap's variables.less
  * the inline comments after the defintion either:
  * provide explanation of the value, for ex.: @color-swatch-a: #00235B; // dark blue;
  * or the comment marked with "//>" holds the bootstrap's original value, for ex.:; @border-radius-base: 0; //> 4px;
  * "//+" indicate the line is an additional declaration not present in the original variables.less
  */


//
// Variables
// --------------------------------------------------


// Global values
// --------------------------------------------------

// Grays
// -------------------------

@gray-darker:            lighten(#000, 13.5%); // #222
@gray-dark:              lighten(#000, 20%);   // #333
@gray:                   lighten(#000, 33.5%); // #555
@gray-light:             lighten(#000, 60%);   // #999
@gray-lighter:           lighten(#000, 93.5%); // #eee
@gray-lightest:          #F5F5F5;

// Brand colors
// -------------------------

//+ Primary color swatches
@color-swatch-a:      #133880; //+ dark blue;
@color-swatch-b:      #00A3E0; //+ light blue;
@color-swatch-c:      #71C5E8; //+ lighter blue;
@color-swatch-d:      #A6192E; //+ dark red;
@color-swatch-e:      #C8102E; //+ lighter red;

//+ Secondary color swatches
@color-secondary-swatch-a:  #FFB81C; //+ yellow;
@color-secondary-swatch-b:  #E57200; //+ orange;
@color-secondary-swatch-c:  #80276C; //+ purple;
@color-secondary-swatch-d:  #00778B; //+ blue;
@color-secondary-swatch-e:  #84BD00; //+ light green =
@color-secondary-swatch-f:  #4C8C2B; //+ darker green

//+ Secondary neutral swatches
@color-neutral-swatch-a:    #B7B09C; //+ light brown;
@color-neutral-swatch-b:    #83786F; //+ darker brown;
@color-neutral-swatch-c:    #5E514D; //+ medium grey;
@color-neutral-swatch-d:    #373A36; //+ dark brown-grey;
@color-neutral-swatch-e:    #74531C;


@brand-primary:         @color-swatch-b; //> #428bca;
@brand-success:         #5cb85c;
@brand-warning:         #f0ad4e;
@brand-danger:          #d9534f;
@brand-info:            #5bc0de;

// Scaffolding
// -------------------------

@body-bg:               #fff;
@text-color:            @gray-dark;

// Links
// -------------------------

@link-color:            @brand-primary;
@link-hover-color:      @brand-primary; // darken(@link-color, 15%);

// Typography
// -------------------------


@font-family-sans-serif:        arial, verdana, sans-serif;
@font-family-sans-serif-alt:    arial, verdana, sans-serif; //+ New alternative definition
@font-weight-normal:            400;

@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

@font-weight-medium:      600; //+ Open Sans Semi-bold

@font-size-base:          14px; //> 20px;
@font-size-large:         ceil(@font-size-base * 1.25); // ~18px
@font-size-xlarge:        ceil(@font-size-base * 2); // ~28px
@font-size-small:         ceil(@font-size-base * 0.85); // ~12px


@font-size-h1:            28px; //> floor(@font-size-base * 2.6); // ~36px
@font-size-h2:            20px; //> floor(@font-size-base * 2.15); // ~30px
@font-size-h3:            @font-size-base; //> ceil(@font-size-base * 1.7); // ~24px
@font-size-h4:            @font-size-base; //> ceil(@font-size-base * 1.25); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil(@font-size-base * 0.85); // ~12px

@line-height-base:        1.357142857142857; // 19/14 default was 1.428571429 = 20/14
@line-height-computed:    floor(@font-size-base * @line-height-base); // ~20px


@line-height-h1:          1.142857142857143; // 32/28
@line-height-h2:          1.2; // 24/20
@line-height-h3:          @line-height-base;
@line-height-h4:          @line-height-base;

@line-height-computed-h1: floor(@font-size-h1* @line-height-h1);

@headings-font-family:    "Open Sans", "HelveticaNeueLT", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
@headings-font-weight:    600; //> 500;
@headings-line-height:    1.1;
@headings-color:          inherit;

@headings-margin-top:     floor(@line-height-computed * 1.33334);
@headings-margin-bottom:  floor(@headings-margin-top/2);

@app-role-heading-color: @gray-light;

// Iconography
// -------------------------

@icon-font-path:          "../fonts/";
@icon-font-name:          "glyphicons-halflings-regular";
@inline-icon-padding:     7px;


// Components
// -------------------------
// Based on 14px font-size and 1.428 line-height (~20px to start)

@padding-base-vertical:          6px;
@padding-base-horizontal:        12px;

@padding-large-vertical:         10px;
@padding-large-horizontal:       16px;

@padding-small-vertical:         5px;
@padding-small-horizontal:       10px;

@line-height-large:              1.33;
@line-height-small:              1.5;

@border-radius-base:             0; //> 4px;
@border-radius-large:            0; //> 6px;
@border-radius-small:            0; //> 3px;

@component-active-color:         #fff;
@component-active-bg:            @color-swatch-a;

@caret-width-base:               4px;
@caret-width-large:              5px;

// Tables
// -------------------------

@table-cell-padding:                 8px;
@table-condensed-cell-padding:       5px;

@table-bg:                           transparent; // overall background-color
@table-bg-accent:                    #f9f9f9; // for striping
@table-bg-hover:                     #f5f5f5;
@table-bg-active:                    @table-bg-hover;

@table-border-color:                 #ddd; // table and cell border


// Buttons
// -------------------------

@btn-font-weight:                normal;


@btn-default-color:              #fff; //> #333;
@btn-default-bg:                 #4d4d4d; //> #fff;
@btn-default-border:             darken(@btn-default-bg, 5%);


@btn-primary-color:              #fff;
@btn-primary-bg:                 @color-swatch-b; //> @brand-primary;
@btn-primary-border:             @color-swatch-b; //> darken(@btn-primary-bg, 5%);

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             darken(@btn-success-bg, 5%);

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             darken(@btn-warning-bg, 5%);

@btn-danger-color:               #fff;
@btn-danger-bg:                  @color-swatch-e; //> @brand-danger;
@btn-danger-border:              @color-swatch-e; //> darken(@btn-danger-bg, 5%);

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                darken(@btn-info-bg, 5%);

@btn-link-disabled-color:        @gray-light;


// Forms
// -------------------------

@input-bg:                       #fff;
@input-bg-disabled:              @gray-lighter;

@input-color:                    @gray;
@input-border:                   #ccc;
@input-border-radius:            @border-radius-base;
@input-border-focus:             #66afe9;

@input-color-placeholder:        @gray-light;

@input-height-base:              (@line-height-computed + (@padding-base-vertical * 2) + 2);
@input-height-large:             (floor(@font-size-large * @line-height-large) + (@padding-large-vertical * 2) + 2);
@input-height-small:             (floor(@font-size-small * @line-height-small) + (@padding-small-vertical * 2) + 2);

@legend-color:                   @gray-dark;
@legend-border-color:            #e5e5e5;

@input-group-addon-bg:           @gray-lighter;
@input-group-addon-border-color: @input-border;

@form-group-margin-bottom:       20px;

// Dropdowns
// -------------------------

@dropdown-bg:                    #fff;
@dropdown-border:                rgba(0,0,0,.15);
@dropdown-fallback-border:       #ccc;
@dropdown-divider-bg:            #e5e5e5;

@dropdown-link-color:            @gray-dark;
@dropdown-link-hover-color:      darken(@gray-dark, 5%);
@dropdown-link-hover-bg:         #eee; //> #f5f5f5;

@dropdown-link-active-color:     @component-active-color;
@dropdown-link-active-bg:        @component-active-bg;

@dropdown-link-disabled-color:   @gray-light;

@dropdown-header-color:          @gray-light;

@dropdown-caret-color:           #000;


// COMPONENT VARIABLES
// --------------------------------------------------


// Z-index master list
// -------------------------
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)

@zindex-navbar:            1000;
@zindex-dropdown:          1000;
@zindex-popover:           1010;
@zindex-tooltip:           1030;
@zindex-navbar-fixed:      1030;
@zindex-modal-background:  1040;
@zindex-modal:             1050;

// Media queries breakpoints
// --------------------------------------------------

// Extra small screen / phone
// Note: Deprecated @screen-xs and @screen-phone as of v3.0.1
@screen-xs:                  480px;
@screen-xs-min:              @screen-xs;
@screen-phone:               @screen-xs-min;

// Small screen / tablet
// Note: Deprecated @screen-sm and @screen-tablet as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
// Note: Deprecated @screen-md and @screen-desktop as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
// Note: Deprecated @screen-lg and @screen-lg-desktop as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);


// Grid system
// --------------------------------------------------

// Number of columns in the grid system
@grid-columns:              12;
// Padding, to be divided by two and applied to the left and right of all columns
@grid-gutter-width:         30px;
// Point at which the navbar stops collapsing
@grid-float-breakpoint:     @screen-sm-min;


// Navbar
// -------------------------

// Basics of a navbar
@navbar-height:                    50px; //> 50px
@navbar-margin-bottom:             0; //> @line-height-computed;
@navbar-border-radius:             @border-radius-base;
@navbar-padding-horizontal:        0; //floor(@grid-gutter-width / 2);
@navbar-padding-vertical:          ((@navbar-height - @line-height-computed - @navbar-border-top-width - 1) / 2); // 1 = navbar-bottom-width hardcoded in bootstrap

@navbar-default-color:             #777;
@navbar-default-bg:                transparent; //> #f8f8f8;
@navbar-default-border:            transparent; //> darken(@navbar-default-bg, 6.5%);

// Navbar links
@navbar-default-link-color:                @gray-dark; //> #777
@navbar-default-link-hover-color:          @color-swatch-e; //> #333
@navbar-default-link-hover-bg:             transparent;
@navbar-default-link-active-color:         @color-swatch-d; //> #555
@navbar-default-link-active-bg:            transparent; //> rgba(166, 25, 46, 0.1 ); //> darken(@navbar-default-bg, 6.5%);
@navbar-default-link-disabled-color:       #ccc;
@navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
@navbar-default-brand-color:               @navbar-default-link-color;
@navbar-default-brand-hover-color:         darken(@navbar-default-brand-color, 10%);
@navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
@navbar-default-toggle-hover-bg:           #fff;
@navbar-default-toggle-icon-bar-bg:        @color-swatch-a; //> #ccc;
@navbar-default-toggle-border-color:       @color-swatch-a; //> #ddd;


// Inverted navbar
//
// Reset inverted navbar basics
@navbar-inverse-color:                      @gray-light;
@navbar-inverse-bg:                         #222;
@navbar-inverse-border:                     darken(@navbar-inverse-bg, 10%);

// Inverted navbar links
@navbar-inverse-link-color:                 @gray-light;
@navbar-inverse-link-hover-color:           #fff;
@navbar-inverse-link-hover-bg:              transparent;
@navbar-inverse-link-active-color:          @navbar-inverse-link-hover-color;
@navbar-inverse-link-active-bg:             darken(@navbar-inverse-bg, 10%);
@navbar-inverse-link-disabled-color:        #444;
@navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
@navbar-inverse-brand-color:                @navbar-inverse-link-color;
@navbar-inverse-brand-hover-color:          #fff;
@navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
@navbar-inverse-toggle-hover-bg:            #333;
@navbar-inverse-toggle-icon-bar-bg:         #fff;
@navbar-inverse-toggle-border-color:        #333;

@navbar-header-height:                      80px;
@navbar-brand-image-height:                 50px;
@navbar-border-top-width:                   3px;
@nav-caret-font-size:                       24px;

@nav-item-height:                           40px;
@nav-vertical-padding:                      floor((@nav-item-height - @line-height-computed)/2) - 1;

@module-nav-item-vertical-padding:          floor((@nav-item-height - @line-height-computed)/2);

// Navs
// -------------------------

@nav-link-padding-vertical:                 8px;
@nav-link-padding-horizontal:               18px;

@nav-link-padding:                          @nav-link-padding-vertical @nav-link-padding-horizontal; //> 10px 15px;
@nav-link-hover-bg:                         @body-bg;

@nav-disabled-link-color:                   @gray-light;
@nav-disabled-link-hover-color:             @gray-light;

@nav-open-link-hover-color:                 #fff;
@nav-open-caret-border-color:               #fff;

// Tabs
@nav-tabs-border-color:                     #ccc; //> #ddd;
@nav-tabs-link-color:                       @gray-light;
@nav-tabs-link-hover-border-color:          transparent; //> @gray-lighter;

@nav-tabs-active-link-hover-bg:             @color-swatch-a;
@nav-tabs-active-link-hover-color:          #fff; //> @gray;
@nav-tabs-active-link-hover-border-color:   @color-swatch-a; //> #ddd;

@nav-tabs-justified-link-border-color:            @nav-tabs-border-color; //> #ddd;
@nav-tabs-justified-active-link-border-color:     @color-swatch-a; //> @body-bg;

// Pills
@nav-pills-border-radius:                   @border-radius-base;
@nav-pills-active-link-hover-bg:            @component-active-bg;
@nav-pills-active-link-hover-color:         @component-active-color;


// Sidebar Vertical Nav
// -------------------------
@sidebar-nav-item-padding-vertical:         10px;

// Pagination
// -------------------------

@pagination-bg:                        #fff;
@pagination-border:                    #ddd;

@pagination-hover-bg:                  #fff;

@pagination-active-bg:                 #fff;
@pagination-active-color:              @color-swatch-a;

@pagination-disabled-color:            @gray-light;


// Pager
// -------------------------

@pager-border-radius:                  15px;
@pager-disabled-color:                 @gray-light;


// Jumbotron
// -------------------------

@jumbotron-padding:              30px;
@jumbotron-color:                inherit;
@jumbotron-bg:                   @gray-lighter;
@jumbotron-heading-color:        inherit;
@jumbotron-font-size:            ceil(@font-size-base * 1.5);


// Form states and alerts
// -------------------------

@state-success-text:             #468847;
@state-success-bg:               #dff0d8;
@state-success-border:           darken(spin(@state-success-bg, -10), 5%);

@state-info-text:                #3a87ad;
@state-info-bg:                  #d9edf7;
@state-info-border:              darken(spin(@state-info-bg, -10), 7%);

@state-warning-text:             #c09853;
@state-warning-bg:               #fcf8e3;
@state-warning-border:           darken(spin(@state-warning-bg, -10), 5%);

@state-danger-text:              #b94a48;
@state-danger-bg:                #f2dede;
@state-danger-border:            darken(spin(@state-danger-bg, -10), 5%);


// Tooltips
// -------------------------
@tooltip-max-width:           200px;
@tooltip-color:               #fff;
@tooltip-bg:                  #000;

@tooltip-arrow-width:         5px;
@tooltip-arrow-color:         @tooltip-bg;


// Popovers
// -------------------------
@popover-bg:                          #fff;
@popover-max-width:                   276px;
@popover-border-color:                rgba(0,0,0,.2);
@popover-fallback-border-color:       #ccc;

@popover-title-bg:                    darken(@popover-bg, 3%);

@popover-arrow-width:                 10px;
@popover-arrow-color:                 #fff;

@popover-arrow-outer-width:           (@popover-arrow-width + 1);
@popover-arrow-outer-color:           rgba(0,0,0,.25);
@popover-arrow-outer-fallback-color:  #999;


// Labels
// -------------------------

@label-default-bg:            @gray-light;
@label-primary-bg:            @brand-primary;
@label-success-bg:            @brand-success;
@label-info-bg:               @brand-info;
@label-warning-bg:            @brand-warning;
@label-danger-bg:             @brand-danger;

@label-color:                 #fff;
@label-link-hover-color:      #fff;


// Modals
// -------------------------
@modal-inner-padding:         20px;

@modal-title-padding:         15px;
@modal-title-line-height:     @line-height-base;

@modal-content-bg:                             #fff;
@modal-content-border-color:                   rgba(0,0,0,.2);
@modal-content-fallback-border-color:          #999;

@modal-backdrop-bg:           #000;
@modal-header-border-color:   #e5e5e5;
@modal-footer-border-color:   @modal-header-border-color;


// Alerts
// -------------------------
@alert-padding:               15px;
@alert-border-radius:         @border-radius-base;
@alert-link-font-weight:      bold;

@alert-success-bg:            @state-success-bg;
@alert-success-text:          @state-success-text;
@alert-success-border:        @state-success-border;

@alert-info-bg:               @state-info-bg;
@alert-info-text:             @state-info-text;
@alert-info-border:           @state-info-border;

@alert-warning-bg:            @state-warning-bg;
@alert-warning-text:          @state-warning-text;
@alert-warning-border:        @state-warning-border;

@alert-danger-bg:             @state-danger-bg;
@alert-danger-text:           @state-danger-text;
@alert-danger-border:         @state-danger-border;


// Progress bars
// -------------------------
@progress-bg:                 #f5f5f5;
@progress-bar-color:          #fff;

@progress-bar-bg:             @brand-primary;
@progress-bar-success-bg:     @brand-success;
@progress-bar-warning-bg:     @brand-warning;
@progress-bar-danger-bg:      @brand-danger;
@progress-bar-info-bg:        @brand-info;


// List group
// -------------------------
@list-group-bg:               #fff;
@list-group-border:           #ddd;
@list-group-border-radius:    @border-radius-base;

@list-group-hover-bg:         #f5f5f5;
@list-group-active-color:     @component-active-color;
@list-group-active-bg:        @component-active-bg;
@list-group-active-border:    @list-group-active-bg;

@list-group-link-color:          #555;
@list-group-link-heading-color:  #333;


// Panels
// -------------------------
@panel-bg:                    #fff;
@panel-inner-border:          #ddd;
@panel-border-radius:         @border-radius-base;
@panel-footer-bg:             #f5f5f5;

@panel-default-text:          @gray-dark;
@panel-default-border:        #ddd;
@panel-default-heading-bg:    #f5f5f5;

@panel-primary-text:          #fff;
@panel-primary-border:        @brand-primary;
@panel-primary-heading-bg:    @brand-primary;

@panel-success-text:          @state-success-text;
@panel-success-border:        @state-success-border;
@panel-success-heading-bg:    @state-success-bg;

@panel-warning-text:          @state-warning-text;
@panel-warning-border:        @state-warning-border;
@panel-warning-heading-bg:    @state-warning-bg;

@panel-danger-text:           @state-danger-text;
@panel-danger-border:         @state-danger-border;
@panel-danger-heading-bg:     @state-danger-bg;

@panel-info-text:             @state-info-text;
@panel-info-border:           @state-info-border;
@panel-info-heading-bg:       @state-info-bg;


// Thumbnails
// -------------------------
@thumbnail-padding:           4px;
@thumbnail-bg:                @body-bg;
@thumbnail-border:            #ddd;
@thumbnail-border-radius:     @border-radius-base;

@thumbnail-caption-color:     @text-color;
@thumbnail-caption-padding:   9px;


// Wells
// -------------------------
@well-bg:                     #f5f5f5;


// Badges
// -------------------------
@badge-color:                 #fff;
@badge-link-hover-color:      #fff;
@badge-bg:                    @gray-light;

@badge-active-color:          @link-color;
@badge-active-bg:             #fff;

@badge-font-weight:           bold;
@badge-line-height:           1;
@badge-border-radius:         10px;


// Breadcrumbs
// -------------------------
@breadcrumb-bg:               #f5f5f5;
@breadcrumb-color:            #ccc;
@breadcrumb-active-color:     @gray-light;
@breadcrumb-separator:        "/";


// Carousel
// ------------------------

@carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6);

@carousel-control-color:                      #fff;
@carousel-control-width:                      15%;
@carousel-control-opacity:                    .5;
@carousel-control-font-size:                  20px;

@carousel-indicator-active-bg:                @btn-default-bg;
@carousel-indicator-border-color:             @gray-light;

@carousel-caption-color:                      #fff;


// Close
// ------------------------
@close-font-weight:           bold;
@close-color:                 #000;
@close-text-shadow:           0 1px 0 #fff;


// Code
// ------------------------
@code-color:                  #c7254e;
@code-bg:                     #f9f2f4;

@pre-bg:                      #f5f5f5;
@pre-color:                   @gray-dark;
@pre-border-color:            #ccc;
@pre-scrollable-max-height:   340px;

// Type
// ------------------------
@text-muted:                  @gray-light;
@abbr-border-color:           @gray-light;
@headings-small-color:        @gray-light;
@blockquote-small-color:      @gray-light;
@blockquote-border-color:     @gray-lighter;
@page-header-border-color:    @gray-lighter;

// Miscellaneous
// -------------------------

// Hr border color
@hr-border:                   @nav-tabs-border-color; //> @gray-lighter

@secondary-action-row-height: 70px;
@space-below-main-nav:        30px;

// Horizontal forms & lists
@component-offset-horizontal: 180px;

@separator-border-dotted:     1px dotted @nav-tabs-border-color;
@paragraph-margin-bottom:     @headings-margin-bottom;

// Padding for .panel
@panel-padding-sm:            20px;

@highlight-row:               #00A3E0;


// Container sizes
// --------------------------------------------------

// Small screen / tablet
@container-tablet:             ((720px + @grid-gutter-width));
@container-sm:                 @container-tablet;

// Medium screen / desktop
@container-desktop:            ((940px + @grid-gutter-width));
@container-md:                 @container-desktop;

// Large screen / wide desktop
@container-large-desktop:      ((1000px + @grid-gutter-width));
@container-lg:                 @container-large-desktop;


// Homepage related
// -----------------

@as-segment-border-width:     5px;
@as-segment-height-lg:        300px;
@as-segment-padding-md:       20px;
@as-segment-padding-lg:       30px;
@as-segment-height-sm:        @as-segment-height-lg * 0.9;
@as-segment-height-xs:        @as-segment-height-lg * 1.25;
@as-segment-margin:           10px;

@as-circle-height:            60px;
@as-circle-font-size:         @font-size-large * 1.3;

@main-pullup-distance:        (@as-segment-height-lg + @as-segment-border-width * 2 + @as-segment-margin * 2) / -2;


// individuals-family page related
// -------------------------------

@stat-circle-height-lg: 250px;
@stat-circle-height-sm: 225px;

@breadcrumb-container-height-lg:  56px;
@breadcrumb-container-height-md:  56px;
@breadcrumb-container-height-sm:  56px;

@breadcrumb-item-vertical-padding:  7px;
@breadcrumb-container-vertical-margin-sm: (@breadcrumb-container-height-sm - (@breadcrumb-item-vertical-padding * 2) - @line-height-computed )/2;

// groups dashboard
// -----------------

@dashboard-group-item-height-sm:      176px;
@dashboard-group-item-height-md:      225px;
@dashboard-group-item-height-lg:      228px;

@dashboard-group-notifs-height-lg:    42px;

@dashboard-group-item-width-sm:       220px;
@dashboard-group-item-width-md:       @dashboard-group-item-height-lg + @dashboard-group-notifs-height-lg;
@dashboard-group-item-width-lg:       @dashboard-group-item-width-md;

@dashboard-group-item-border-width:   1px;
@dashboard-group-item-border:         @dashboard-group-item-border-width solid @nav-tabs-border-color;

@btn-circular-medium-size:            50px;
@btn-circular-large-size:             250px;
